import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { graphql } from 'gatsby'
import Prism from 'prismjs'

import kotlin from 'prismjs/components/prism-kotlin'

import '../../styles/modules/mobile.scss'
import '../../styles/modules/code-tabs.scss'
import TutorialTiles from '../../tutorials/components/TutorialTiles'

import androidHero from '../../assets/images/mobile/android/android-hero-logo.png'


const Android = ({ data }) => {
  const [currentTab, setCurrentTab] = useState(0)
  useEffect(() => {
    Prism.highlightAll([kotlin])
  }, [currentTab])
  const tutorials = data.tutorials.nodes

  const handleTabFocus = (index) => {
    setCurrentTab(index)
  }

  return (
    <div className='page--android flex flex-col'>
      <Helmet title={`Android | Couchbase Developer Portal`} />

      <div className='appdev-red text-white'>
        <div className='wrapper pt-12 sm:pt-20 pb-8 sm:pb-24 grid grid-cols-1 sm:grid-cols-2 gap-12 place-items-center'>
          <div className='order-last sm:order-first'>
            <h2>Android</h2>
            <p>
              Couchbase Lite is a modern, robust, embedded document-based database for mobile and native apps. Features
              such as Live Query work well with Kotlin Co-Routines.
            </p>
            <p>
              In addition, with offline data access and data synchronization via Sync Gateway, your users can interact
              with your application regardless of their internet connection. So if you are building an application for
              Android with Java or Kotlin, we've got your back.
            </p>
          </div>
          <div>
            <img src={androidHero} alt='android hero' className='float-right' />
          </div>
        </div>
      </div>

      <div className='bg-gray-230'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Getting Started</h2>
          <p>Resources that can help you quickly get up to speed on using Couchbase Lite and Sync Gateway to build
            modern applications.</p>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 py-4'>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Install SDK</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Lite is distributed from a Maven repository.
                There are separate downloads for Kotlin and Java.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/gs-install.html' target='_blank' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Learn</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>The Couchbase Mobile learning path for Kotlin 
                walks you through adding Couchbase Lite SDK to your Android application and enabling features like
                query, indexes, and data sync.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://developer.couchbase.com//learn/android-kotlin-app-services' target='_blank' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Troubleshooting</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>The Couchbase Lite documentation has excellent
                documentation on troubleshooting using the robust Logging API. It also has information on
                troubleshooting queries and application crashes.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/troubleshooting-logs.html' target='_blank' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='appdev-blue flex flex-row w-full justify-center text-white'>
        <div className='appdev-section flex justify-end grid grid-cols-1 sm:grid-cols-2 -ml-4'>
          <div className='code-bg-android'>
          </div>
          <div className='pt-8 mb-12'>
            <h2 className='text-right'>Upgrade to Latest Release</h2>
            <p className='text-right'>Excited about upgrading your app to the latest release of Couchbase Lite? We have
              documentation that goes through the API changes that can quickly get you up to speed with the code changes
              required to upgrade.</p>
            <a href='https://docs.couchbase.com/couchbase-lite/current/android/upgrade.html' target='_blank' rel='noopener noreferrer' className='text-right block mt-4'>Learn More</a>
          </div>
        </div>
      </div>

      <div className='py-12 wrapper'>
        <div className='min-codetabs'>
          <h2 className='py-6 text-center'>What does developing for Android look like?</h2>
          <Tabs onSelect={handleTabFocus}>
            <TabList>
              <Tab>
                <p>Open Database</p>
              </Tab>
              <Tab>
                <p>Save Document</p>
              </Tab>
              <Tab>
                <p>Update Document</p>
              </Tab>
              <Tab>
                <p>Delete Document</p>
              </Tab>
              <Tab>
                <p>Query Builder</p>
              </Tab>
              <Tab>
                <p>SQL++ Query</p>
              </Tab>
              <Tab>
                <p>Live Query</p>
              </Tab>
            </TabList>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-kotlin codeblock'>
                  <code>
                    {
  `// open database code goes in this block
  val cfg = DatabaseConfigurationFactory.create()
  val database = Database("mydb", cfg)
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-kotlin codeblock'>
                  <code>
                    {
  `// Create a new document (i.e. a record) in the database.
  var mutableDoc = MutableDocument()
    .setFloat("version", 2.0f)
    .setString("type", "SDK")
    
  // Save it to the database
  database.save(mutableDoc)
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-kotlin codeblock'>
                  <code>
                    {
  `// update document code goes in this block
  mutableDoc = database
                .getDocument(mutableDoc.id)
                !!.toMutable()
                .setString("language", "Java")
  database.save(mutableDoc)
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-kotlin codeblock'>
                  <code>
                    {
  `// delete document code goes in this block
  doc = database
    .getDocument(id)
  database.delete(doc)
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-kotlin codeblock'>
                  <code>
                    {
  `// query builder code goes in this block
  val rs = QueryBuilder
            .select(SelectResult.all())
            .from(DataSource.database(database))
            .where(Expression.property("type")
            .equalTo(Expression.string("SDK")))
            .execute()
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-kotlin codeblock'>
                  <code>
                    {
  `// sql++ query code goes in this block
  val query = database.
            createQuery("SELECT * FROM _ WHERE type = 'SDK'")
  val rs = query.execute().allResults()
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='panel-content'>
                <pre className='language-kotlin codeblock'>
                  <code>
                    {
  `// live query code goes in this block
  val query = QueryBuilder
      .select(SelectResult.all())
      .from(DataSource.database(database)) 
  
  // Adds a query change listener.
  // Changes will be posted on the main queue.
  val token = query.addChangeListener { change ->
      change.results?.let {
          for (result in it) {
              Log.d(TAG, "results: \${result.keys}")
              /* Update UI */
          }
      } 
  }
   `}
                  </code>
                </pre>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>

      <div className='bg-gray-230'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Our Favorite Features</h2>
          <p>Couchbase Mobile has some amazing features that can help you build more dynamic applications using Kotlin or Java.</p>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 py-4'>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Live Query</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>A live query is a query that, once activated, remains
                active and monitors the database for changes; refreshing the result set whenever a change occurs. As
                such, it is a great way to build reactive user interfaces.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/query-live.html' alt='live query' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Data Sync - Sync Gateway</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Lite for Android provides API support for secure,
                bi-directional, synchronization of data changes between mobile applications and a central server
                database.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/replication.html' alt='sync gateway' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Full-Text Search</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>The Couchbase Lite supports full-text search using SQL++
                or QueryBuilder syntax.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/fts.html' alt='full-text search' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Pre-built Database</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Lite's support for pre-built databases means you
                can pre-load your app with data instead of syncing it down from Sync Gateway during startup.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/prebuilt-database.html' alt='pre-built database' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Document - Data Modeling</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Learn about Couchbase Lite JSON document support including
                field types, data encoding, and how to structure a document.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/document.html' alt='documents - data model' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Data Sync - Peer-to-Peer</h3>
              <p className='text-left pb-8 sm:pb-10 md:pb-12'>Couchbase Lite’s peer-to-peer synchronization solution
                offers secure storage and bidirectional synchronization of data between edge devices without the need
                for a centralized cloud-based control point.</p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/p2psync-websocket.html' alt='data sync peer-to-peer' target='_blank'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white'>
        <div className='py-12 wrapper mx-auto text-center'>
          <h2>Tutorials</h2>
          <p>Learn to build and operate apps using Couchbase.</p>
          <div className='text-left'>
            <TutorialTiles tutorials={tutorials} showCategories={false} gridTilesContainerClass={'grid-tiles-container-blue-3col'} />
          </div>
          <a href='/tutorials' target='_blank'
                   rel='noopener noreferrer' alt='more tutorials' className='cb-black-button-large'>More Tutorials</a>
        </div>
      </div>


      <div className='bg-blue-400 w-full justify-center py-10 text-white'>
        <div className='text-center wrapper'>
          <h2>Other Resources</h2>
          <p>
            This is a list of other resources developers can use to quickly answer questions you might have on
            developing applications on the Couchbase Mobile platform.
          </p>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 my-8'>
            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>API Reference</h3>
              <p className='text-left pb-10'>
                Full documentation for the Couchbase Lite SDK for Android with Kotlin or Java.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/mobile/3.0.0/couchbase-lite-android/' target='_blank' alt='api reference'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Release Notes</h3>
              <p className='text-left pb-10'>
                Describes the key features and changes implemented by the latest release of Couchbase Lite.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/releasenotes.html' target='_blank'
                   alt='release notes' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Compatibility</h3>
              <p className='text-left pb-10'>
                Identifies the compatibility of Couchbase Lite on Android with Sync Gateway, together with the operating
                systems upon which it is supported.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/compatibility.html' target='_blank' alt='compatibility'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Supported Platforms</h3>
              <p className='text-left pb-10'>
                Couchbase Lite for Android—the OS and SDK versions on which this framework is supported.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/supported-os.html' target='_blank' alt='supported platforms'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Handling Data Conflicts</h3>
              <p className='text-left pb-10'>
                Document conflicts can occur if multiple changes are made to the same version of a document by multiple
                peers in a distributed system. For Couchbase Mobile, this can be a Couchbase Lite or Sync Gateway
                database instance.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/android/conflict.html' target='_blank'
                   alt='handling data conflicts' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Forum</h3>
              <p className='text-left pb-10'>
                Official Couchbase Forums for Couchbase Mobile Product Suite.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://forums.couchbase.com/tag/mobile' target='_blank' alt='forums'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Lite</h3>
              <p className='text-left pb-10'>
                Couchbase Lite, our embedded database, manages and stores data locally on the device. It has full CRUD
                and SQL++ query functionality, and supports all major platforms including iOS, OS X, tvOS, Android,
                Linux, Windows, Xamarin, Kotlin, Ionic, and more.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/couchbase-lite/current/index.html' target='_blank' alt='couchbase lite'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Sync Gateway</h3>
              <p className='text-left pb-10'>
                Couchbase Mobile includes synchronization between Couchbase Lite and Couchbase Server, and peer-to-peer
                synchronization between Couchbase Lite instances. Synchronization is orchestrated by Sync Gateway, our
                secure gateway.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/sync-gateway/current/introduction.html' target='_blank'
                   alt='sync gateway' rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>

            <div className='card-white'>
              <h3 className='divider-below-center divider-dark'>Couchbase Server</h3>
              <p className='text-left pb-10'>
                Couchbase Server, our database server, manages and stores data in the cloud. It scales easily to
                billions of records and terabytes of data, supports millions of concurrent users, and provides 24x365
                uptime.
              </p>
              <div className='absolute bottom-6 left-0 w-full text-center'>
                <a href='https://docs.couchbase.com/home/server.html' target='_blank' alt='couchbase server'
                   rel='noopener noreferrer'
                   className='inline-block text-sm sm:text-base pt-4 btn-arrow-right no-arrow hover:no-underline text-blue-400 hover:text-blue-400 font-bold'>Learn
                  More</a>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Android


export const query = graphql`
  query tutorialsQueryAndroid {
    tutorials: allMarkdownRemark(
      skip: 1 
      limit: 6
      filter: {frontmatter: {content_type: {in: ["tutorial", "quickstart"]}, filter: {eq: "mobile"}, tags: {in: "Android"}}}
      sort: {fields: [frontmatter___landing_order], order: ASC}
    ) {
      nodes {
        frontmatter {
          path
          content_type
          title
          technology
          filter
          description
          length
          tags
          landing_page
          landing_order
        }
        id
      }
    }
  }
`
